<template>
    <v-card>
        <div class="mx-auto container container--fluid">
            <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
                <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
                <v-btn dark text @click="snackbar = false">Close</v-btn>
            </v-snackbar>

                <v-progress-circular style="position: absolute; top: 30%; left: 50%;z-index: 100;" :size="70" :width="7" v-if="isLoading"  indeterminate color="primary"></v-progress-circular>
               
                <br><br>

                <v-toolbar-title>Import Placed Student</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-row justify="end" class="ma-4">
                     <v-btn color="blue" dark @click="exceldialog = !exceldialog"
          >Excel Import</v-btn
        ></v-row
      >
                        <v-dialog width="350" persistent v-model="exceldialog">
                            <v-card>
                            <v-card-title class="c-title">Excel import</v-card-title>
                            <v-card-text
                                ><br />
                                <v-row justify="end">
                                <a :href="url">
                                    <v-icon large color="blue" class="icon-quick" title="Download">
                                    mdi-file-excel
                                    </v-icon>
                                </a>
                                </v-row>
                                <v-file-input
                                v-model="excelfile"
                                label="Import Excel"
                                ></v-file-input>
                                <v-select
                                :items="placementTypelist"
                                label="Offer type"
                                prepend-icon="mdi-file"
                                item-text="name"
                                item-value="id"
                                v-model="placement_type"
                                ></v-select>
                            </v-card-text>
                            <v-card-actions>
                                <v-row class="pr-5" justify="end">
                                <v-btn @click="exceldialog=false" text>cancel</v-btn>
                                <v-btn @click="uploadexcel()" dark color="blue">Upload</v-btn>
                                </v-row>
                            </v-card-actions>
                            </v-card>
                        </v-dialog>
         
            
            <v-divider class="mx-4"></v-divider>
     
                    <v-data-table  id="exceltable"  :headers="headers" :items="successarray" class="elevation-1" :search="search">
                        <template v-slot:top>
                            <v-toolbar flat color="white">                              
                                <v-text-field v-model="search" append-icon="search" label="Search " single-line hide-details></v-text-field>
                                <v-spacer></v-spacer>
                                <v-btn  style="margin-right:50px" v-if="successarray.length != 0"  @click="exportexcel()" color="success" dark class="mb-2">
                                    <v-icon dark>mdi-file-excel</v-icon>
                                </v-btn>
                                
                              
                            </v-toolbar>
                        </template>                       
                    </v-data-table>
              
               
                    <v-data-table  v-if="errorarray.length !=0 " id="exceltable"  :headers="headers1" :items="errorarray" class="elevation-1" :search="search">
                        <template v-slot:top>
                            <v-toolbar flat color="white">                              
                                <v-text-field v-model="search" append-icon="search" label="Search " single-line hide-details></v-text-field>
                                <v-spacer></v-spacer>
                                <v-btn  style="margin-right:50px" v-if="errorarray.length != 0"  @click="exportexcel()" color="success" dark class="mb-2">
                                    <v-icon dark>mdi-file-excel</v-icon>
                                </v-btn>
                                
                              
                            </v-toolbar>
                        </template>                       
                    </v-data-table>
               
        </div>
        <v-dialog v-model="dialog_send" hide-overlay persistent width="300">
            <v-card color="#4CAF50" dark>
                <v-card-text>
                    Please wait while file is Importing...
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import axios from "axios";
    import {table2excel} from "../jquery.table2excel";
    import readXlsxFile from "read-excel-file";
    export default {
        data: () => ({
            valid: false,
            overlay: false,
            snackbar_msg: "",
            iserpapplicable:"",
            color: "",
            snackbar: false,
            iseditable: false,
            load: false,
            placement_type:"",
            excelfile:null,
           placementTypelist:[],
            learner_list: null,
            StudRecord: null,
            exceldialog:false,
            errorMsg: {
                organization: false,
            },
            search: "",
            file: null,
            output_load: false,
            loading3: false,
            dialog_send: false,
            isLoading: false,
            splitedStr: "",
             name: '',
            
            headers: [
            {
                    text: "Excel Row No",
                    value: "rowno",
                },
                {
                    text: "Registration Number",
                    value: "grno",
                },
                {
                    text: "Message",
                    value: "msg",
                },
            ],
             headers1: [
             {
                    text: "Excel Row No",
                    value: "rowno",
                },
                {
                    text: "Registration Number",
                    value: "grno",
                },
                {
                    text: "Message",
                    value: "msg",
                },
            ],
         
            successarray:[],
            errorarray:[],
            editedIndex: -1,
            editedItem: {
                id: 0,
                name: "",
                fname:"",
                mname:"",
                lname:"",
                email: "",
                organization: "",
                program: "",
                isactive:true,
                department: "",
                year: "",
                contact: "",
                registration_number: "",
            },
            defaultItem: {
                id: 0,
                name: "",
                email: "",
                organization: "",
                program: "",
                isactive:true,
                department: "",
                contact: "",
                year: "",
                registration_number: "",
            },
        }),

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? "New Student" : "Edit Student";
            },
        },

        watch: {
            dialog(val) {
                val || this.close();
            },
            overlay(val) {
                val = () => {
                    this.overlay = false;
                };
            },
        },
        mounted() {
            this.onload()
        },

        methods: {
            getFullName(){
                   // alert("in getFullName");
                    this.editedItem.name = this.editedItem.fname+" "+this.editedItem.mname+" "+this.editedItem.lname

            },
             exportexcel() {    
               
        $("#exceltable").table2excel({  
          name: "Worksheet Name",
          filename: "Tpo_PlacedLerner", //do not include extension
          fileext: ".xls" // file extension
        });        
      },
            onload(){
                this.isLoading=true;
                axios
                .post("/AdminImport/importPlacedStudentHome")
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.isLoading=false;
                        this.load = true;
                        this.placementTypelist = res.data.placementTypelist;
                        this.url = res.data.url;
                        // console.log("placementTypelist ")
                        // console.log(this.placementTypelist)
                    } else {
                        this.isLoading=false;
                                window.console.log(res.data.msg)
                            }
                })
                .catch(error => {
                    this.isLoading=false;
                            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                            // window.console.log(error);
                        })
                        .finally(() => {
                            // 'use strict';
                            var overlay = false;
                        });
            },
            initializeErrorMsgFalse() {
                this.errorMsg.organization = false;
            },
         
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.initializeErrorMsgFalse();
                    this.editedIndex = -1;
                }, 300);
            },
            uploadexcel() {
                       // alert("in uploas")
                        // console.log(this.excelfile);
                        // console.log(this.placement_type)
                      if (this.excelfile == null) {
                            this.showSnackbar("red", "Please select file");
                        } else if (this.placement_type == "") {
                            this.showSnackbar("red", "Please select offertype");
                        } else {
                            var flag = 0;
                            var j = 0;
                            var studentlist = [];
                            readXlsxFile(this.excelfile).then((rows) => {
                         
                            var i;
                            for (i = 1; i < rows.length; i++) {
                                studentlist[i - 1] = [rows[i][0] ? rows[i][0] : "-",rows[i][1]? rows[i][1] : "-",rows[i][2]? rows[i][2] : "-"];
                            }
                            var params = {
                                studentlist: studentlist,
                                offertype: this.placement_type,
                            };
                            this.isLoading=true;
                            axios.post("/AdminImport/importPlacedStudentFile", params).then((res) => {
                                if (res.data.msg == "200") {
                                    this.isLoading=false;
                                this.errorarray = res.data.errorarray;
                                this.successarray = res.data.successarray;
                                this.showSnackbar("green", "Success..");
                                } else {
                                    this.isLoading=false;
                                this.showSnackbar("red", res.data.msg);
                                }
                            });
                            });

                            this.exceldialog = false;
                        }
            }, //end of the fuction
            loadData() {
                axios
                    .post("/AdminImport/importStudentHome")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.load = true;
                            this.learner_list = res.data.learner_list;
                            // this.program_list = res.data.program_list;
                            // this.dept_list = res.data.dept_list;
                        } else {
                        }
                    })
                    .catch((error) => {
                        window.console.log(error);
                    });
            },
            checkExtention() {
                if (this.file != null) {
                    var file = this.file.name;
                    var ext = file.split(".").pop();
                    if (ext == "csv") {
                        return true;
                    } else {
                        // console.log("false")
                        // alert("Upload only CSV file!");
                        return false;
                    }
                } else {
                    return false;
                }
            },
        
            save() {

            //    alert("before name"+this.editedItem.name)
            //     this.splitedStr = this.editedItem.name.split(" ");
            //     alert("after name"+this.splitedStr)
            //     this.editedItem.name=this.splitedStr
            //     alert("this.item: "+this.editedItem.name)
         


                 if (this.editedItem.registration_number == "") {
                        this.showSnackbar("red", "please Enter Registration Number");
                    } else if (this.editedItem.name == "") {
                        this.showSnackbar("red", "please Enter Name");
                    }
                    else if (this.editedItem.email == "") {
                        this.showSnackbar("red", "please Enter Email");
                    }
                    else if (this.editedItem.contact == "") {
                        this.showSnackbar("red", "please Enter Contact Number");
                    }
                    else if (this.editedItem.program == "") {
                        this.showSnackbar("red", "please Select Program");
                    }
                    else if (this.editedItem.department == "") {
                        this.showSnackbar("red", "please Select Department");
                    }
                    else if (this.editedItem.year == "") {
                        this.showSnackbar("red", "please Select Year");
                    }
                     else if (this.editedItem.organization == "") {
                        this.showSnackbar("red", "please Select Organization");
                    }
                else{
                    if (this.editedIndex > -1) {
                        //  console.log("edit.." + this.editedItem);
                        this.iseditable = false;
                        axios
                            .post("/AdminImport/editStudent", this.editedItem)
                            .then((res) => {
                                if (res.data.status.code == "SUCCESS") {
                                    // console.log(this.editedItem);
                                    Object.assign(this.learner_list[this.editedIndex], this.editedItem);
                                    this.onload()
                                    this.showSnackbar("#4caf50", "Student details updated Successfully..."); // show snackbar on success
                                } else if (res.data.status.code == "NA") {
                                    //alert(res.data.status.message)
                                    this.showSnackbar("#b71c1c", "Something went wrong!!!"); // show snackbar on error
                                }
                            })
                            .catch((error) => {
                                this.showSnackbar("#b71c1c", "Something went wrong!!!"); // show snackbar
                                // window.console.log(error);
                            });
                    } else {
                        axios
                            .post("/AdminImport/saveStudent", this.editedItem)
                            .then((res) => {
                                if (res.data.status.code == "SUCCESS") {
                                    this.learner_list.push(this.editedItem);
                                    this.onload()
                                    this.showSnackbar("#4caf50", "New Student Added Successfully..."); // show snackbar on success
                                } else if (res.data.status.code == "NA") {
                                    //alert(res.data.status.message)
                                    this.showSnackbar("#b71c1c", "Student Already Present!!!"); // show snackbar on error
                                }
                            })
                            .catch((error) => {
                                window.console.log(error);
                            });
                    }
                    this.close();
                }    
            },
             activeswitch(item) {
    
      const data = {
        deleteitem: item.id,
      };
    //   console.log(item.id);

      axios
        .post("/AdminImport/activestudent", data)
        .then((res) => {
        //   console.log("res.data");
        //   console.log(res.data);
          if (res.data.msg == "200") {
            // console.log("res.data");
            // console.log(res.data);
            // console.log("success");
            this.showSnackbar("#4caf50", res.data.message);
            this.onLoad();
          } else {
            // console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
            isValidated() {
                if (this.editedItem.organization) {
                    return true;
                } else {
                    if (!this.editedItem.organization) {
                        this.errorMsg.organization = true;
                    }

                    return false;
                }
            }, // .....end of isValidated()
        },
    };
</script>
<style scoped>
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }

    .v-icon-size {
        font-size: 20px;
    }

    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    .downbtn {
        float: right;
    }

    .edit-avatar:hover {
        background-color: #b0bec5;
    }

    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }

    .edit-v-icon:hover {
        color: white;
    }
</style>
